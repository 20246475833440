<template>
<div class="card">
  <div class="bg-white card-header">
    <h6 class="card-title font-weight-semibold">Form Kajian TBC</h6>
  </div>
  <validation-observer ref="VForm">
    <b-form @submit.prevent="doSubmit()">
      <div class="row">
        <div class="col-6">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Anamnesa </h6>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="perKeluhan">Keluhan Utama<strong class="text-danger">*</strong></label>
                    <b-form-textarea v-model="row.keluhan_utama" rows="2" placeholder="Keluhan Utama" @change="updateRow(row)"></b-form-textarea>

                    <VValidate name="Keluhan Utama" v-model="row.keluhan_utama" :rules="mrValidation.keluhan_utama" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="perKeluhan">Riwayat Penyakit Sekarang</label>
                    <b-form-textarea v-model="row.riwayat_penyakit_sekarang" rows="2" placeholder="Riwayat Penyakit Sekarang" @change="updateRow(row)"></b-form-textarea>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="perKeluhan">Riwayat Penyakit Dahulu</label>
                    <b-form-textarea v-model="row.riwayat_penyakit_dahulu" rows="2" placeholder="Riwayat Penyakit Dahulu" @change="updateRow(row)"></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-md-12">
              <div class="head_panel_red">
                <div class="d-flex justify-content-between align-items-center">
                  <h3>ALERGI </h3>
                  <b-form-radio-group v-model="row.has_alergi" :options="Config.mr.yesNoOptV5.reverse()" class="mb-0" @change="updateRow(row)">
                  </b-form-radio-group>
                </div>
              </div>
              <table class="table table-sm table-bordered" v-if="row.has_alergi == 'Y'">
                <thead>
                  <tr>
                    <th width="33%">Jenis</th>
                    <th>Informasi Alergi</th>
                    <th width="64"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in row.alergi" :key="`k-alergi-${k}`">
                    <td>
                      <v-select :options="$parent.mrJenisAlergi" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 104px;" v-model="v.jenis" @input="updateRow(row)" />
                    </td>
                    <td>
                      <b-form-input type="text" placeholder="cth. amoxicilin" v-model="v.name" @change="updateRow(row)" />
                    </td>
                    <td>
                      <a href="javascript:;" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger" @click="removeData('alergi', k)" v-if="row.alergi.length > 1">
                        <i class="icon-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="3" class="text-center">
                      <a href="javascript:;" class="btn btn-sm alpha-info border-info" @click="addData('alergi')"><i class="icon-plus2 mr-1"></i>Tambah</a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Tanda Tanda Vital</h6>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Tekanan Darah<small class="txt_mandatory">*</small></label>
                    <div class="input-group">
                      <b-form-input type="tel" name="name" class="form-control" placeholder="Sistolik" v-model="row.sistolik" :formatter="Formatter.number" @change="updateRow(row)" />
                      <div class="input-group-append input-group-prepend"><span class="input-group-text">/</span></div>
                      <b-form-input type="tel" class="form-control" placeholder="Diastolik" v-model="row.diastolik" :formatter="Formatter.number" @change="updateRow(row)" />
                      <div class="input-group-append"><span class="input-group-text">mmHG</span></div>
                    </div>

                    <VValidate name="Tekanan Darah" :value="row.sistolik && row.diastolik" :rules="mrValidation.sistolik" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Nadi<small class="txt_mandatory">*</small></label>
                    <div class="input-group">
                      <b-form-input type="text" class="form-control" v-model="row.nadi" :formatter="Formatter.number" @change="updateRow(row)" />

                      <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                      <div class="input-group-append">
                        <v-select :options="MonitoringOptions.mrNadi" :reduce="(v) => v.value" label="label" placeholder="-- Pilih --" style="min-width: 104px;" v-model="row.nadi_type" @input="updateRow(row)" />
                      </div>
                    </div>

                    <VValidate name="Nadi" :value="row.nadi && row.nadi_type" :rules="mrValidation.nadi" />
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>GDA</label>
                    <div class="form-row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input type="tel" v-model="row.gda" :formatter="Formatter.number" @change="updateRow(row)" />
                          <div class="input-group-append"><span class="input-group-text">mg/dL</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Pernafasan<small class="txt_mandatory">*</small></label>
                    <div class="input-group">
                      <b-form-input type="tel" v-model="row.pernafasan" :formatter="Formatter.number" @change="updateRow(row)" />
                      <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>SPO2<small class="txt_mandatory">*</small></label>
                    <div class="form-row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input type="text" v-model="row.sp_o2" :formatter="Formatter.decimalNumber" @change="updateRow(row)" />
                          <div class="input-group-append"><span class="input-group-text">%</span>
                          </div>
                        </div>

                        <VValidate name="SPO2" v-model="row.sp_o2" :rules="mrValidation.sp_o2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label>Suhu</label>
                    <div class="form-row">

                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input :formatter="Formatter.decimalNumber" v-model="row.suhu" type="text" class="form-control" @change="updateRow(row)" />
                          <div class="input-group-append"><span class="input-group-text">C</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <VValidate name="Suhu" v-model="row.suhu" :rules="{ ...mrValidation.suhu }" />
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Berat Badan</label>
                    <div class="form-row">

                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input @input="hitungBMI()" :formatter="Formatter.number" v-model="row.berat_badan" type="text" class="form-control" @change="updateRow(row)" />
                          <div class="input-group-append"><span class="input-group-text">kg</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <VValidate name="Berat Badan" v-model="row.berat_badan" :rules="{ ...mrValidation.berat_badan }" />
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Tinggi</label>
                    <div class="form-row">

                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input @input="hitungBMI()" :formatter="Formatter.number" v-model="row.tinggi_badan" type="text" class="form-control" @change="updateRow(row)" />
                          <div class="input-group-append"><span class="input-group-text">cm</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <VValidate name="Tinggi Badan" v-model="row.tinggi_badan" :rules="{ ...mrValidation.tinggi_badan }" />
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label>Lingkar Kepala</label>
                    <div class="form-row">

                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input :formatter="Formatter.number" v-model="row.lingkar_kepala" type="text" class="form-control" @change="updateRow(row)" />
                          <div class="input-group-append"><span class="input-group-text">cm</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <VValidate name="Lingkar Kepala" v-model="row.lingkar_kepala" :rules="{ ...mrValidation.lingkar_kepala }" />
                  </div>
                </div>

                <div class="col-md-3" v-if="row.ap_usia >= 1">
                  <div class="form-group">
                    <label>BMI</label>
                    <div class="form-row">
                      <div class="col-md-12">
                        <div class="input-group">
                          <b-form-input disabled v-model="row.bmi" type="text" class="form-control" @change="updateRow(row)" />
                          <div class="input-group-append"><span class="input-group-text">m2</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-3" v-if="row.ap_usia <= 15">
                  <div class="form-group">
                    <label>Luas Permukaan Tubuh Anak</label>
                    <div class="input-group">
                      <b-form-input :formatter="Formatter.number" v-model="row.luas_permukaan_tubuh_anak" type="text" class="form-control" @change="updateRow(row)" />
                      <div class="input-group-append"><span class="input-group-text">m<sup>2</sup></span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Pengkajian Fisik</h6>
            </div>
            <div class="card-body p-3">
              <div id="tbcFormCheck">
                <div class="row">
                  <div class="col-md-4 col-lg-2">
                    <div class="form-group">
                      <label for="tbcUmum">Kondisi Umum <strong class="text-danger">*</strong></label>
                      <v-select v-model="row.kondisi_umum" :options="$parent.mrKondisiUmum" label="label" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Glasgow Coma Scale (GCS) <strong class="text-danger">*</strong></label>
                      <div class="form-row">
                        <div class="col-md-4">
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text">E</span>
                            </div>
                            <div class="form-control p-0 border-0">
                              <v-select v-model="row.gcs_response_mata" :options="$parent.mrGCS.responseMata" label="label" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                                <template #option="{ label, value }">
                                  <span>({{ value }}): {{ label }}</span>
                                </template>

                                <template #selected-option="{ label, value }">
                                  <span>({{ value }}): {{ label.limitWord(6) }}</span>
                                </template>
                              </v-select>

                              <VValidate name="GCS Response Mata" v-model="row.gcs_response_mata" :rules="mrValidation.gcs_response_mata" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text">V</span>
                            </div>

                            <div class="form-control p-0 border-0">
                              <v-select v-model="row.gcs_response_verbal" :options="$parent.mrGCS.responseVerbal" label="label" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                                <template #option="{ label, value }">
                                  <span>({{ value }}): {{ label }}</span>
                                </template>

                                <template #selected-option="{ label, value }">
                                  <span>({{ value }}): {{ label.limitWord(6) }}</span>
                                </template>
                              </v-select>

                              <VValidate name="GCS Response Verbal" v-model="row.gcs_response_verbal" :rules="mrValidation.gcs_response_verbal" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="input-group">
                            <div class="input-group-prepend"><span class="input-group-text">M</span>
                            </div>
                            <div class="form-control p-0 border-0">
                              <v-select v-model="row.gcs_response_motorik" :options="$parent.mrGCS.responseMotorik" label="label" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                                <template #option="{ label, value }">
                                  <span>({{ value }}): {{ label }}</span>
                                </template>

                                <template #selected-option="{ label, value }">
                                  <span>({{ value }}): {{ label.limitWord(12) }}</span>
                                </template>
                              </v-select>

                              <VValidate name="GCS Response Motorik" v-model="row.gcs_response_motorik" :rules="mrValidation.gcs_response_motorik" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="wrap_line bg-white mt-2">
                  <h3>Kepala</h3>
                  <div class="row">
                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div>
                        <label for="headKonjungtiva">Konjungtiva</label>
                        <v-select v-model="row.kep_konjungtiva" placeholder="Pilih Konjungtiva" :options="Config.mr.configStatusKonjungtiva" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                        <VValidate name="Konjungtiva" v-model="row.kep_konjungtiva" :rules="{required: 1}" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div>
                        <label for="Sianosis">Sianosis</label>

                        <v-select v-model="row.kep_sianosis" placeholder="Pilih Sianosis" :options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                        <VValidate name="Sianosis" v-model="row.kep_sianosis" :rules="{required: 1}" />

                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div>
                        <label for="Sklera">Sklera</label>
                        <v-select v-model="row.kep_sklera" placeholder="Pilih Sklera" :options="Config.mr.configStatusSklera" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                        <VValidate name="Sklera" v-model="row.kep_sklera" :rules="{required: 1}" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div>
                        <label for="Hidung">Hidung</label>
                        <v-select v-model="row.kep_hidung" placeholder="Pilih Hidung" :options="Config.mr.configStatusHidung" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                        <VValidate name="Hidung" v-model="row.kep_hidung" :rules="{required: 1}" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="wrap_line">
                  <h3>Thorax / Dada</h3>
                  <div class="row">
                    <div class="col-md-4 col-lg-3">
                      <div>
                        <label for="thoraxParu">Paru</label>
                        <v-select v-model="row.trx_paru" placeholder="Pilih Dada" :options="Config.mr.configStatusParu" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                        <div class="mt-1" v-if="row.trx_paru == 'Lain-lain'">
                          <input type="text" :formatter="Formatter.normalText" v-model="row.trx_paru_lainnya" class="form-control">

                          <VValidate name="Paru Lainnya" v-model="row.trx_paru_lainnya" :rules="{required: 1, min :3}" />
                        </div>
                      </div>
                      <VValidate name="Paru" v-model="row.trx_paru" :rules="{required: 1}" />
                    </div>

                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div>
                        <label for="thoraxRespirasi">Respirasi</label>
                        <v-select v-model="row.trx_respirasi" placeholder="Pilih Respirasi" :options="Config.mr.configStatusRespirasi" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                      </div>

                      <VValidate name="Respirasi" v-model="row.trx_respirasi" :rules="{required: 1}" />
                    </div>
                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div>
                        <label for="thoraxAlatBantuNafas">Alat Bantu Nafas</label>
                        <v-select v-model="row.trx_alat_bantu_nafas" placeholder="Pilih Alat Bantu Nafas" :options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                      </div>

                      <VValidate name="Alat Bantu Nafas" v-model="row.trx_alat_bantu_nafas" :rules="{required: 1}" />
                    </div>
                    <div class="col-md-4 col-lg-3">
                      <div>
                        <label for="thoraxJantung">Jantung</label>

                        <v-select v-model="row.trx_jantung" placeholder="Pilih Jantung" :options="Config.mr.configStatusJantung" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>

                        <div class="mt-1" v-if="row.trx_jantung=='Lain-lain'">
                          <input type="text" v-model="row.trx_jantung_note" class="form-control" @change="updateRow(row)">

                          <VValidate name="Jantung Lainnya" v-model="row.trx_jantung_note" :rules="{required: 1, min: 3}" />
                        </div>
                      </div>

                      <VValidate name="Jantung" v-model="row.trx_jantung" :rules="{required: 1}" />
                    </div>
                  </div>
                </div>
                <div class="wrap_line">
                  <h3>Abdomen</h3>
                  <div class="row">
                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div class="form-group">
                        <label for="abdNyeriTekanParu">Nyeri Tekan</label>
                        <v-select v-model="row.abd_nyeri" placeholder="Pilih Nyeri" :options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                      </div>
                      <VValidate name="Nyeri" v-model="row.abd_nyeri" :rules="{required: 1}" />
                    </div>
                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div class="form-group">
                        <label for="abdBisingUsus">Bising Usus</label>
                        <v-select v-model="row.abd_bising_usus" placeholder="Pilih Nyeri" :options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>
                      </div>
                      <VValidate name="Bising Usus" v-model="row.abd_bising_usus" :rules="{required: 1}" />
                    </div>

                    <div class="col-md-6">
                      <div>
                        <label for="abdKonAbdomen">Kondisi Abdomen</label>
                        <div class="form-row">
                          <div class="col-md-6">
                            <v-select v-model="row.abd_kondisi" placeholder="Pilih Kondii Abdomen" :options="Config.mr.configKondisiAbdomen" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                            </v-select>
                          </div>
                          <div class="col-md-6" v-if="row.abd_kondisi=='Lain-lain'">
                            <input type="text" v-model="row.abd_kondisi_lainnya" class="form-control" @change="updateRow(row)">

                            <VValidate name="Kondisi Lainnya" v-model="row.abd_kondisi_lainnya" :rules="{required: 1, min: 3}" />
                          </div>

                          <VValidate name="Kondisi Abdomen" v-model="row.abd_kondisi" :rules="{required: 1}" />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="wrap_line">
                  <h3>Ekstremitas</h3>
                  <div class="row">
                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div>
                        <label for="ekstremitasEdema">Edema</label>
                        <v-select v-model="row.eks_edema" placeholder="Pilih Edema" :options="Config.mr.yesNoOptV2" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>

                        <VValidate name="Edema" v-model="row.eks_edema" :rules="{required: 1}" />
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-3 col-xl-2">
                      <div>
                        <label for="ekstremitasAkaral">Akral</label>
                        <v-select v-model="row.eks_akaral" placeholder="Pilih Akral" :options="Config.mr.configAkaral" label="text" :clearable="true" :reduce="v=>v.value" @input="updateRow(row)">
                        </v-select>

                        <VValidate name="Akral" v-model="row.eks_akaral" :rules="{required: 1}" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="pemeriksaanTCM">Hasil Pemeriksaan TCM</label>
                      <b-form-textarea v-model="row.aka_hasil_tcm" :formatter="Formatter.normalText" name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Hasil Pemeriksaan TCM" @change="updateRow(row)"></b-form-textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="pemeriksaanTCM">Hasil Pemeriksaan BTA</label>
                      <b-form-textarea v-model="row.aka_hasil_bta" :formatter="Formatter.normalText" name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Hasil Pemeriksaan BTA" @change="updateRow(row)"></b-form-textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="pemeriksaanTCM">Hasil Pemeriksaan Thorax</label>
                      <b-form-textarea v-model="row.aka_hasil_thorax" :formatter="Formatter.normalText" name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Hasil Pemeriksaan Thorax" @change="updateRow(row)"></b-form-textarea>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="pemeriksaanTCM">Hasil Pemeriksaan PA / Mantoux Test</label>
                      <b-form-textarea v-model="row.aka_hasil_test" :formatter="Formatter.normalText" name="perRiwayat" id="perRiwayat" rows="2" class="form-control" placeholder="Hasil Pemeriksaan PA / Mantoux Test" @change="updateRow(row)"></b-form-textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-2">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Edukasi </h6>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group>
                    <b-form-checkbox-group v-model="row.edukasi" :options="$parent.mrEdukasi" value-field="value" text-field="label" @change="updateRow(row)"></b-form-checkbox-group>
                  </b-form-group>

                  <VValidate name="Edukasi" v-model="row.edukasi" :rules="mrValidation.edukasi" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mt-2" v-if="false">
          <div class="card mb-0">
            <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Asesmen Keperawatan</h6>
            </div>
            <div class="card-body mt-3">
              <div class="row">
                <div class="col-md-12">
                  <div class="card border shadow-0">
                    <div class="card-header bg-info">
                      <h5 class="card-title font-weight-semibold">Standar Diagnosis Keperawatan Indonesia (SDKI)</h5>
                    </div>
                    <div class="card-header pt-0">
                      <ul class="nav nav-tabs nav-tabs-bottom mb-0">

                        <li class="nav-item" v-for="(v,k) in diagnosa" :key="k">
                          <a href="javascript:;" @click="changeTab(k+1)" data-toggle="tab" :data-target="'#diagTab'+(k+1)" :class="isActiveTab == (k+1) ? 'nav-link active' : 'nav-link'">{{v.text||"Diagnosa baru"}}
                            <i @click="removeDiagnosa(v.arkts_id,k)" class="icon-cross ml-2"></i>
                          </a>
                        </li>

                        <li class="nav-item"><a @click="addNewDiagnosa" href="javascript:;" class="nav-link">
                            <i class="icon-plus2 mr-2"></i> Tambah Diagnosa
                          </a></li>
                      </ul>
                    </div>

                    <div class="card-body">
                      <div class="tab-content">
                        <div v-for="(v,k) in diagnosa" :key="k+'a'" :class="isActiveTab == (k+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#diagTab'+(k+1)">
                          <div class="row g-3">
                            <div class="col-md-8">
                              <label for="diagnosa">Pilih Diagnosa</label>
                              <v-select placeholder="Pilih Diagnosa" class="diagnosa-section select-paging" v-model="diagnosa[k].arkts_diagnosa_id" @input="selectPenyebab($event,k)" @search="filterSDKI($event,k)" :options="getMasterSDKI($parent.mSDKI,diagnosa,k)" label="text" :clearable="true" :reduce="v=>v.value">
                                <template slot="selected-option">
                                  <span>{{ v.text || "-" }}</span>
                                </template>

                                <template #list-footer>
                                  <li class="list-paging" v-if="ceilData(v.totalRows/10)">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <b-button @click="changePageSDKI(v.currentPage,k,'min','diagnosa')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                      <small>Halaman {{v.currentPage}} dari {{ceilData(v.totalRows/10)}}</small>
                                      <b-button @click="changePageSDKI(v.currentPage,k,'plus','diagnosa')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                  </li>
                                </template>
                              </v-select>

                              <VValidate :name="`Diagnosa #${k+1}`" v-model="diagnosa[k].arkts_diagnosa_id" :rules="{required: 1}" />
                            </div>
                            <div class="col-6" v-if="diagnosa[k].arkts_diagnosa_id">
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Penyebab</h6>
                                </div>
                                <div class="card-body p-3">
                                  <b-form-checkbox-group v-if="((diagnosa[k].arkts_config_sdki||{}).msd_penyebab||[]).length" :id="'penyebab'+k" v-model="diagnosa[k].arkts_penyebab" :name="'penyebab'+k" stacked>

                                    <template v-for="(vpeny,kpeny) in ((diagnosa[k].arkts_config_sdki||{}).msd_penyebab||[])">
                                      <b-form-checkbox :value="vpeny.name" @change="checkPenyebab(vpeny.name,k)" :key="kpeny">{{vpeny.name}}</b-form-checkbox>
                                    </template>

                                  </b-form-checkbox-group>
                                  <span v-else> (Tidak Ada) </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-6" v-if="diagnosa[k].arkts_diagnosa_id">
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
                                </div>
                                <div class="card-body p-3">
                                  <div class="row gx-4">
                                    <div class="col-md-6">
                                      <div class="signsCheck">
                                        <b-form-checkbox-group v-if="((diagnosa[k].arkts_config_sdki||{}).msd_faktor_resiko||[]).length" v-model="diagnosa[k].arkts_faktor_resiko" :options="(diagnosa[k].arkts_config_sdki||{}).msd_faktor_resiko||[]" :id="'faktor_resiko'+k" :name="'faktor_resiko'+k" value-field="name" text-field="name" stacked></b-form-checkbox-group>
                                        <span v-else> (Tidak Ada) </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12" v-if="diagnosa[k].arkts_diagnosa_id">
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
                                </div>
                                <div class="card-body p-3">
                                  <div class="row gx-4">
                                    <div class="col-md-6">
                                      <div class="signsCheck">
                                        <h6 class="font-weight-semibold">Subjective</h6>
                                        <b-form-checkbox-group v-if="((diagnosa[k].arkts_config_sdki||{}).msd_gejala_mayor_subjektif||[]).length" v-model="diagnosa[k].arkts_gejala_mayor_subjektif" :options="(diagnosa[k].arkts_config_sdki||{}).msd_gejala_mayor_subjektif||[]" :id="'mayor_sub'+k" :name="'mayor_sub'+k" value-field="name" text-field="name" stacked></b-form-checkbox-group>
                                        <span v-else> (Tidak Ada) </span>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="signsCheck">
                                        <h6 class="font-weight-semibold">Objective</h6>
                                        <b-form-checkbox-group v-if="((diagnosa[k].arkts_config_sdki||{}).msd_gejala_mayor_objektif||[]).length" v-model="diagnosa[k].arkts_gejala_mayor_objektif" :options="(diagnosa[k].arkts_config_sdki||{}).msd_gejala_mayor_objektif||[]" :id="'mayor_obj'+k" :name="'mayor_obj'+k" value-field="name" text-field="name" stacked></b-form-checkbox-group>
                                        <span v-else> (Tidak Ada) </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12" v-if="diagnosa[k].arkts_diagnosa_id">
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
                                </div>
                                <div class="card-body p-3">
                                  <div class="row gx-4">
                                    <div class="col-md-6">
                                      <div class="signsCheck">
                                        <h6 class="font-weight-semibold">Subjective</h6>
                                        <b-form-checkbox-group v-if="((diagnosa[k].arkts_config_sdki||{}).msd_gejala_minor_subjektif||[]).length" v-model="diagnosa[k].arkts_gejala_minor_subjektif" :options="(diagnosa[k].arkts_config_sdki||{}).msd_gejala_minor_subjektif||[]" :id="'minor_sbj'+k" :name="'minor_sbj'+k" value-field="name" text-field="name" stacked></b-form-checkbox-group>
                                        <span v-else> (Tidak Ada) </span>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="signsCheck">
                                        <h6 class="font-weight-semibold">Objective</h6>
                                        <b-form-checkbox-group v-if="((diagnosa[k].arkts_config_sdki||{}).msd_gejala_minor_objektif||[]).length" v-model="diagnosa[k].arkts_gejala_minor_objektif" :options="(diagnosa[k].arkts_config_sdki||{}).msd_gejala_minor_objektif||[]" :id="'minor_obj'+k" :name="'minor_obj'+k" value-field="name" text-field="name" stacked></b-form-checkbox-group>
                                        <span v-else> (Tidak Ada) </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-12">
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Standar Luaran Keperawatan Indonesia (SLKI)</h6>
                                </div>
                                <div class="card-header">
                                  <ul class="nav nav-tabs nav-tabs-bottom mb-0">

                                    <li class="nav-item" v-for="(v1,k1) in v.slki" :key="'Luaran'+k1">
                                      <a href="javascript:;" @click="changeTabSLKI(k1+1,k)" data-toggle="tab" :data-target="'#luarTab'+(k1+1)" :class="v.arkts_last_tab_slki == (k1+1) ? 'nav-link active' : 'nav-link'">
                                        <!--
                                                    {{v.arkts_last_tab_slki}} {{(k1+1)}}
                                                    -->

                                        {{ v1.text || "Luaran Baru"}}
                                        <i @click="removeLuaran(v1.arktsl_id,k,k1)" class="icon-cross ml-2"></i> </a>
                                    </li>

                                    <li class="nav-item"><a href="javascript:;" @click="addLuaran(v.arkts_diagnosa_id, v.arkts_id, k)" class="nav-link">
                                        <i class="icon-plus2 mr-2"></i> Tambah Luaran
                                      </a></li>

                                  </ul>
                                </div>

                                <div class="tab-content">
                                  <div v-for="(v1,k1) in v.slki" :key="k1+'lu'" :class="v.arkts_last_tab_slki == (k1+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#luarTab'+(k1+1)">
                                    <div class="card-body">
                                      <div class="row">
                                        <div class="col-md-8">
                                          <div>
                                            <label for="diagnosa">Pilih Luaran</label>
                                            <v-select placeholder="Pilih Luaran" class="diagnosa-section select-paging" v-model="diagnosa[k]['slki'][k1]['arktsl_diagnosa_luaran_id']" @search="filterSLKI($event,k,k1)" :options="getMasterSLKI(diagnosa[k]['mSlki'],diagnosa[k]['slki'],k1)" @input="selectedKriteriaHasil($event,k,k1)" label="text" :clearable="true" :reduce="v=>v.value">

                                              <template slot="selected-option">
                                                <span v-b-tooltip.hover.right placement='right' :title="v1.text">{{ v1.text }}</span>
                                              </template>
                                              <template slot="option" slot-scope="option">
                                                <span v-b-tooltip.hover.right placement='right' :title="option.text">{{ option.text }}</span>
                                              </template>

                                              <template #list-footer>
                                                <li class="list-paging" v-if="ceilData(v1.totalRows/10)">
                                                  <div class="d-flex align-items-center justify-content-between">
                                                    <b-button @click="changePageSLKI(v1.currentPage,k,k1,'min','diagnosa','slki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                                    <small>Halaman {{v1.currentPage}} dari {{ceilData(v1.totalRows/10)}}</small>
                                                    <b-button @click="changePageSLKI(v1.currentPage,k,k1,'plus','diagnosa','slki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                                  </div>
                                                </li>
                                              </template>
                                            </v-select>

                                          </div>
                                          <VValidate :name="`Luaran Keperawatan #${k+1} #${k1+1}`" v-model="diagnosa[k]['slki'][k1]['arktsl_diagnosa_luaran_id']" :rules="{required: 1}" />
                                        </div>
                                      </div>
                                    </div>
                                    <table class="table table-bordered table-striped table-hover" v-if="diagnosa[k]['slki'][k1]['arktsl_diagnosa_luaran_id']">
                                      <thead>
                                        <tr>
                                          <th class="table-info" colspan="99">Kriteria Hasil</th>
                                        </tr>
                                        <tr>
                                          <th>Kriteria</th>
                                          <th colspan="5">Skor</th>
                                          <th width="64">Aksi</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(v11, k11) in (v1.arktsl_kriteria_hasil||[])" :key="k11+'u'">
                                          <td width="400">
                                            <v-select placeholder="Pilih Kriteria" v-model="diagnosa[k]['slki'][k1].arktsl_kriteria_hasil[k11]['name']" :options="getMasterKriteriaHasil(diagnosa[k]['slki'][k1]['kriteriaHasil'],diagnosa[k]['slki'][k1].arktsl_kriteria_hasil,k11)
                                                " label="mks_criteria_hasil" :clearable="true" :reduce="v=>v.mks_criteria_hasil"></v-select>

                                            <VValidate :name="`Kriteria hasil #${k+1} #${k1+1} #${k11+1}`" v-model="diagnosa[k]['slki'][k1].arktsl_kriteria_hasil[k11]['name']" :rules="{required: 1, min: 2, max: 512}" />
                                          </td>
                                          <td width="64" v-for="(vc,kc) in Config.mr.kriteriaHasil" :key="kc">
                                            <div>
                                              <b-form-radio v-model="diagnosa[k]['slki'][k1].arktsl_kriteria_hasil[k11]['hasil']" :value="vc.value" :name="'scoringKrit'+k11" class="form-check-input-styled">{{vc.text}}
                                              </b-form-radio>
                                            </div>
                                          </td>
                                          <td>
                                            <a href="javascript:;" class="list-icons-item" @click="deleteKriteria(k,k1,k11)" data-toggle="tooltip" data-placement="top" title="Delete"><i class="icon-bin"></i></a>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td colspan="99" class="text-center">
                                            <a href="javascript:;" @click="addNewKriteria(k,k1)" class="btn btn-outline alpha-blue text-blue-800 border-blue-600">
                                              <i class="icon-plus2 mr-2"></i> Tambah Kriteria
                                            </a>
                                          </td>
                                        </tr>
                                      </tfoot>
                                    </table>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div class="col-12">
                              <div class="card mb-0">
                                <div class="card-header bg_head_panel">
                                  <h6 class="card-title font-weight-semibold">Standar Intervensi Keperawatan Indonesia (SIKI)</h6>
                                </div>
                                <div class="card-header">
                                  <ul class="nav nav-tabs nav-tabs-bottom mb-0">

                                    <li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
                                      <a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab" :data-target="'#intvTab'+(k2+1)" :class="v.arkts_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
                                        {{ v2.text || "Intervensi Baru"}}
                                        <i @click="removeIntervensi(v2.arktksi_id,k,k2,diagnosa[k]['siki'][k2]['arktksi_diagnosa_intervensi_id'])" class="icon-cross ml-2"></i> </a>
                                    </li>

                                    <li class="nav-item"><a href="javascript:;" @click="addIntervensi(v.arkts_diagnosa_id, v.arkts_aranrm_id, v.arkts_id, k)" class="nav-link">
                                        <i class="icon-plus2 mr-2"></i> Tambah Intervensi
                                      </a></li>

                                  </ul>
                                </div>
                                <div class="tab-content">
                                  <div v-for="(v2,k2) in v.siki" :key="k2+'sik'" :class="v.arkts_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#intvTab1'+(k2+1)">
                                    <div class="card-body">
                                      <div class="row">
                                        <div class="col-md-8">
                                          <div>
                                            <label for="diagnosa">Pilih Intervensi</label>
                                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Intervensi" @search="filterSIKI($event,k,k2)" v-model="diagnosa[k]['siki'][k2]['arktksi_diagnosa_intervensi_id']" :options="getMasterSIKI(diagnosa[k]['mSiki'],diagnosa[k]['siki'],k2) " @input="selectedIntervensi($event,k,k2)" label="text" :clearable="true" :reduce="v=>v.value">

                                              <template slot="selected-option">
                                                <span v-b-tooltip.hover.right placement='right' :title="v2.text">{{ v2.text }}</span>
                                              </template>
                                              <template slot="option" slot-scope="option">
                                                <span v-b-tooltip.hover.right placement='right' :title="option.text">{{ option.text }}</span>
                                              </template>

                                              <template #list-footer>
                                                <li class="list-paging" v-if="ceilData(v2.totalRows/10)">
                                                  <div class="d-flex align-items-center justify-content-between">
                                                    <b-button @click="changePageSIKI(v2.currentPage,k,k2,'min','diagnosa','siki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-left22 text-white"></i></b-button>
                                                    <small>Halaman {{v2.currentPage}} dari {{ceilData(v2.totalRows/10)}}</small>
                                                    <b-button @click="changePageSIKI(v2.currentPage,k,k2,'plus','diagnosa','siki')" size="sm" variant="transparent" class="btn-icon bg-blue-300"><i class="icon-arrow-right22 text-white"></i></b-button>
                                                  </div>
                                                </li>
                                              </template>
                                            </v-select>

                                          </div>

                                          <VValidate :name="`Intervensi Keperawatan #${k+1} #${k2+1}`" v-model="diagnosa[k]['siki'][k2]['arktksi_diagnosa_intervensi_id']" :rules="{required: 1}" />

                                        </div>
                                      </div>
                                    </div>
                                    <table v-if="diagnosa[k]['siki'][k2]['arktksi_diagnosa_intervensi_id']" class="table table-bordered table-striped table-hover">
                                      <thead>
                                        <tr>
                                          <th class="table-primary" colspan="99">Tindakan</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th colspan="2" class="table-light">OBSERVASI</th>
                                        </tr>

                                        <tr v-for="(vo,ko) in diagnosa[k]['siki'][k2]['arktksi_tindakan_observasi']" :key="ko+'ko'">
                                          <td width="64">
                                            <div class="form-check"><label class="form-check-label">
                                                <b-form-checkbox value="Y" :unchecked-value="null" v-model="diagnosa[k]['siki'][k2]['arktksi_tindakan_observasi'][ko]['value']" @input="addTindakanKeperawatan($event,vo.tindakan,'OBSERVASI',k,('ko'+k2+ko),diagnosa[k]['siki'][k2]['arktksi_diagnosa_intervensi_id'])" :name="'tinInt'+k2+ko" class="form-check-input-styled" />
                                              </label></div>
                                          </td>
                                          <td>{{vo.name}}</td>
                                        </tr>
                                      </tbody>
                                      <tbody>
                                        <tr>
                                          <th colspan="2" class="table-light">TERAPEUTIK</th>
                                        </tr>
                                        <tr v-for="(vt,kt) in diagnosa[k]['siki'][k2]['arktksi_tindakan_terapeutik']" :key="kt+'kt'">
                                          <td width="64">
                                            <div class="form-check"><label class="form-check-label">
                                                <b-form-checkbox value="Y" :unchecked-value="null" v-model="diagnosa[k]['siki'][k2]['arktksi_tindakan_terapeutik'][kt]['value']" @input="addTindakanKeperawatan($event,vt.tindakan,'TERAPEUTIK',k,('kt'+k2+kt),diagnosa[k]['siki'][k2]['arktksi_diagnosa_intervensi_id'])" :name="'tinTer'+kt" class="form-check-input-styled" />
                                              </label></div>
                                          </td>
                                          <td>{{vt.name}}</td>
                                        </tr>
                                      </tbody>

                                      <tbody>
                                        <tr>
                                          <th colspan="2" class="table-light">EDUKASI</th>
                                        </tr>
                                        <tr v-for="(ve,ke) in diagnosa[k]['siki'][k2]['arktksi_tindakan_edukasi']" :key="ke+'ke'">
                                          <td width="64">
                                            <div class="form-check"><label class="form-check-label">
                                                <b-form-checkbox value="Y" :unchecked-value="null" v-model="diagnosa[k]['siki'][k2]['arktksi_tindakan_edukasi'][ke]['value']" @input="addTindakanKeperawatan($event,ve.tindakan,'EDUKASI',k,('ke'+k2+ke),diagnosa[k]['siki'][k2]['arktksi_diagnosa_intervensi_id'])" :name="'tinEd'+ke" class="form-check-input-styled" />
                                              </label></div>
                                          </td>
                                          <td>{{ve.name}}</td>
                                        </tr>
                                      </tbody>

                                      <tbody>
                                        <tr>
                                          <th colspan="2" class="table-light">KOLABORASI</th>
                                        </tr>
                                        <tr v-for="(vk,kk) in diagnosa[k]['siki'][k2]['arktksi_tindakan_kolaborasi']" :key="kk+'kk'">
                                          <td width="64">
                                            <div class="form-check"><label class="form-check-label">
                                                <b-form-checkbox value="Y" :unchecked-value="null" v-model="diagnosa[k]['siki'][k2]['arktksi_tindakan_kolaborasi'][kk]['value']" @input="addTindakanKeperawatan($event,vk.tindakan,'KOLABORASI',k,('kk'+k2+kk),diagnosa[k]['siki'][k2]['arktksi_diagnosa_intervensi_id'])" :name="'tinKol'+kk" class="form-check-input-styled" />
                                              </label></div>
                                          </td>
                                          <td>{{vk.name}}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="card border shadow-0">
                                <div class="card-header bg-info">
                                  <h5 class="card-title font-weight-semibold">Tindakan Keperawatan</h5>
                                </div>
                                <div>
                                  <table class="table table-striped table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Diagnosa</th>
                                        <th>Tindakan Keperawatan</th>
                                        <th width="185">PPA</th>
                                        <th>Waktu</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(vti,kti) in (v.arkts_tindakan_keperawatan||[])" :key="kti">
                                        <td v-if="kti == 0" :rowspan="(v.arkts_tindakan_keperawatan||[]).length">
                                          {{v.text||"Diagnosa baru"}}</td>
                                        <td>{{vti.name||"-"}}</td>
                                        <td>
                                          <b-form-group class="mt-3 fg-margin">
                                            <b-form-radio-group :options="Config.mr.isPerawatOrDokter" v-model="vti.ppa" />
                                          </b-form-group>
                                          <template>
                                            <b-form-input v-model="vti.ppaLainnya" placeholder="Tulis Nama" />
                                          </template>
                                          <VValidate :name="`PPA`" v-model="vti.ppa" :rules="{required: 1}" />
                                        </td>
                                        <td>
                                          <div class="input-group">
                                            <datepicker @input="changePos(k)" input-class="form-control transparent" placeholder="Tanggal Awal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="vti.dates">
                                            </datepicker>
                                            <div class="input-group-append calendar-group">
                                              <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                          </div>

                                          <div class="input-group mt-2">
                                            <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="vti.time">
                                            </vue-timepicker>
                                            <div v-if="vti.time && !vti.time.HH && !vti.time.mm" id="Waktu. Kejadian" class="label_error" style="">The Waktu. Kejadian field is required</div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr v-if="!(v.arkts_tindakan_keperawatan||[]).length">
                                        <td colspan="99">Tidak ada Data</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div class="card-body" v-if="(v.arkts_tindakan_keperawatan||[]).length">
                                  <div class="row">
                                    <div class="form-group col-6">
                                      <label for="addInfoTindakan">Nama Perawat</label>
                                      <b-form-input v-model="diagnosa[k]['arkts_nama_perawat']" :formatter="Formatter.normalText" placeholder="Tulis Nama" />
                                    </div>

                                    <div class="form-group col-6">
                                      <label for="addInfoTindakan">Waktu Tindakan</label>
                                      <vue-timepicker manual-input v-model="diagnosa[k].arkts_tindakan_pada" format="HH:mm" input-class="form-control"></vue-timepicker>
                                    </div>
                                  </div>

                                  <div class="form-group">
                                    <label for="addInfoTindakan">Tindakan Keperawatan</label>
                                    <b-textarea v-model="diagnosa[k]['arkts_informasi_tindakan']" :formatter="Formatter.normalText" rows="6" class="form-control" placeholder="Tindakan Keperawatan">
                                    </b-textarea>

                                    <VValidate :name="`Tindakan Keperawatan #${k+1}`" v-model="diagnosa[k].arkts_informasi_tindakan" :rules="{min: 2}" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <button type="button" class="btn" @click="$router.back()">Kembali</button>
          <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
        </div>
      </div>
    </b-form>
  </validation-observer>

  <b-modal v-model="openFollowUpTindakan" :title="'Penunjang Rekam Medis'" size="sm" ok-title="Submit" @ok="submitFollowUp">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Follow Up Tindakan<small class="txt_mandatory">*</small></label>
          <b-textarea v-model="rowFollowUp.followup" :formatter="Formatter.normalText" rows="6" class="form-control" placeholder="Informasi Tindakan" spellcheck="false"></b-textarea>
        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import Formatter from '@/libs/Formatter.js'
import Config from '@/libs/Config.js'
import MonitoringOptions from '@/libs/MonitoringOptions.js'
const moment = require('moment')

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  data() {
    return {
      rowData: {},
      isActiveTab: null,
      isMonitoring: false,
      openFollowUpTindakan: false,
      rowFollowUp: {},
    }
  },
  components: {
    Datepicker,
    VueTimepicker
  },
  computed: {
    isParent() {
      return this.$parent
    },
    row() {
      return this.$parent.row
    },
    diagnosa() {
      return this.$parent.diagnosa
    },
    dataKajianPerawat() {
      return this.$parent.dataKajianPerawat
    },
    mrValidation() {
      return this.$parent.mrValidation
    },

    Config() {
      return Config
    },
    Formatter() {
      return Formatter
    },
    MonitoringOptions() {
      return MonitoringOptions
    }
  },
  methods: {
    checkPenyebab(e, k) {
      this.diagnosa[k].arkts_penyebab = []
      this.diagnosa[k].arkts_penyebab = [e]
    },

    hitungBMI(){
      if (this.row.berat_badan && this.row.tinggi_badan) {
        this.row.bmi = this.row.berat_badan / ((this.row.tinggi_badan/100)*(this.row.tinggi_badan/100))
        this.row.bmi = this.row.bmi.toFixed(2)
      } else {
        this.row.bmi = null
      }
    },
    ceilData(value) {
      return Math.ceil(value)
    },
    changeTab(k) {
      this.isActiveTab = k
    },
    changeTabSDKI(k) {
      this.activeTabSDKI = k
    },
    changeTabSLKI(k1, k) {
      this.diagnosa[k].aukpaksd_last_tab_slki = k1
      this.updateRow(this.row)
    },

    changeTabSIKI(k2, k) {
      this.diagnosa[k].aukpaksd_last_tab_siki = k2
      this.updateRow(this.row)
    },
    addNewKriteria(k, k1) {
      this.diagnosa[k]['slki'][k1]['arktsl_kriteria_hasil'].push({
        hasil: 1,
        name: ''
      })
    },
    deleteKriteria(k, k1, k11) {
      this.diagnosa[k]['slki'][k1]['arktsl_kriteria_hasil'].splice(k11, 1)
    },

    addNewDiagnosa() {
      this.loadingOverlay = true
      let data = {
        arkts_arankptb_id: this.row.id,
        type: 'add-diagnosa'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        resp.row.arkts_last_tab_slki = 0
        resp.row.arkts_last_tab_siki = 0
        this.diagnosa.push(resp.row)
        this.isActiveTab = this.diagnosa.length
        this.loadingOverlay = false
      })
    },
    addLuaran(diagnosa, arkts_id, k) {
      this.loadingOverlay = true
      let data = {
        arktsl_diagnosa_id: diagnosa,
        arktsl_arkts_id: arkts_id,
        arktsl_kriteria_hasil: [],
        type: 'add-luaran'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.diagnosa[k]['slki'].push(resp.row)
        this.diagnosa[k]['arkts_last_tab_slki'] = resp.countBySDKI
        this.diagnosa[k]['mSlki'] = resp.mSlki
        this.loadingOverlay = false
      })
    },
    addIntervensi(diagnosa, aranrm_id, arkts_id, k) {
      this.loadingOverlay = true
      let data = {
        arktksi_diagnosa_id: diagnosa,
        arktksi_arkts_id: arkts_id,
        arktksi_aranrm_id: aranrm_id,
        arktksi_tindakan_observasi: [],
        arktksi_tindakan_terapeutik: [],
        arktksi_tindakan_edukasi: [],
        arktksi_tindakan_kolaborasi: [],
        arktksi_data: [],
        arktksi_tindakan_tambahan: [],

        type: 'add-intervensi'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        let resp = res.data
        this.diagnosa[k]['siki'].push(resp.row)
        this.diagnosa[k]['arkts_last_tab_siki'] = resp.countBySDKI
        this.diagnosa[k]['mSiki'] = resp.mSiki
        this.loadingOverlay = false
      })
    },
    selectedIntervensi(e, k, k2) {
      let index = this.diagnosa[k]['mSiki'].findIndex(x => x.value == e)
      if (index !== -1) {
        this.diagnosa[k]['siki'][k2]['arktksi_tindakan_observasi'] = this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_observasi']

        this.diagnosa[k]['siki'][k2]['arktksi_tindakan_terapeutik'] = this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_terapeutik']

        this.diagnosa[k]['siki'][k2]['arktksi_tindakan_edukasi'] = this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_edukasi']

        this.diagnosa[k]['siki'][k2]['arktksi_tindakan_kolaborasi'] = this.diagnosa[k]['mSiki'][index]['msi_tindakan_perawat_kolab']

        this.diagnosa[k]['siki'][k2]['text'] = this.diagnosa[k]['mSiki'][index]['text']
      }
    },

    removeDiagnosa(id, k) {
      this.loadingOverlay = true
      let data = {
        id: id,
        type: 'remove-diagnosa'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(() => {
        this.diagnosa.splice(k, 1)
        this.isActiveTab = this.diagnosa.length
        this.loadingOverlay = false
      })
    },

    removeLuaran(id, k, k1) {
      this.loadingOverlay = true
      let data = {
        id: id,
        type: 'remove-luaran'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(() => {
        this.diagnosa[k]['slki'].splice(k1, 1)
        this.loadingOverlay = false
        this.diagnosa[k]['arkts_last_tab_slki'] = this.diagnosa[k]['slki'].length
      })
    },

    removeIntervensi(id, k, k2, intervensiId) {
      this.loadingOverlay = true

      let dataTindakan = _.filter(this.diagnosa[k].arkts_tindakan_keperawatan, v => {
        return v.idSiki == intervensiId
      })

      for (let i = 0; i < (dataTindakan || []).length; i++) {
        let index = this.diagnosa[k].arkts_tindakan_keperawatan.findIndex(x => x.idSiki == intervensiId)
        if (index !== -1) {
          this.diagnosa[k].arkts_tindakan_keperawatan.splice(index, 1)
        }
      }

      let data = {
        id: id,
        type: 'remove-intervensi'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(() => {
        this.diagnosa[k]['siki'].splice(k2, 1)
        this.loadingOverlay = false
        this.diagnosa[k]['arkts_last_tab_siki'] = this.diagnosa[k]['siki'].length
      })
    },

    openDetailFollowUp(v, k, kP) {
      this.openFollowUpTindakan = true
      this.rowFollowUp = v
      this.rowFollowUp.key = k
      this.rowFollowUp.keyParent = kP
    },

    submitFollowUp() {
      this.diagnosa[this.rowFollowUp.keyParent]['arktks_tindakan_keperawatan'][this.rowFollowUp.key]['followup'] = this.rowFollowUp.followup
    },

    getMasterSDKI(master, diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.aukpaksd_diagnosa_id
      })
      return _.filter(master, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    getMasterSLKI(master, diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.aukpaksl_diagnosa_luaran_id
      })
      return _.filter(master, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    getMasterSIKI(master, diagnosa, k) {
      const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.aukpaksi_diagnosa_intervensi_id
      })
      return _.filter(master, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    getMasterKriteriaHasil(master, kriteriaHasil, k) {
      const sldMenu = _.map(_.filter(kriteriaHasil || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2.name
      })

      return _.filter(master, v2 => {
        return sldMenu.indexOf(v2.mks_criteria_hasil) <= -1
      })
    },

    addTindakanKeperawatan(e, name, type, k, id, idSiki) {
      // add
      if (e) {
        const newData = {
          name: `(${type}) ${name}`,
          dates: moment().format('YYYY-MM-DD'),
          time: {
            'HH': moment().format('HH'),
            'mm': moment().format('mm')
          },
          ppaLainnya: this.$parent.user.fullName,
          ppa: "Perawat",
          followup: "",
          id: id,
          idSiki: idSiki
        }

        if (!this.diagnosa[k].arkts_tindakan_keperawatan) {
          this.diagnosa[k].arkts_tindakan_keperawatan = [newData]
        } else {
          this.diagnosa[k].arkts_tindakan_keperawatan.push(newData)
        }
      } else {
        let index = this.diagnosa[k].arkts_tindakan_keperawatan.findIndex(x => x.id == id)
        if (index !== -1) {
          this.diagnosa[k].arkts_tindakan_keperawatan.splice(index, 1)
        }
      }
    },

    changePos(k) {
      let sortedObjs = _.sortBy(this.diagnosa[k].arktsi_tindakan_keperawatan, 'dates')
      this.diagnosa[k].arktsi_tindakan_keperawatan = sortedObjs
    },

    selectedKriteriaHasil(e, k, k1) {
      let index = this.diagnosa[k]['mSlki'].findIndex(x => x.value == e)
      if (index !== -1) {
        this.diagnosa[k]['slki'][k1]['text'] = this.diagnosa[k]['mSlki'][index]['text']
      }

      let data = {
        id: e,
        type: 'get-kriteria-hasil'
      }
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.diagnosa[k]['slki'][k1]['kriteriaHasil'] = res.data.mKriteriaHasil
      })
    },

    changePageSDKI(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this.isParent[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this.isParent[col][k]['totalRows'] / 10)) {
          page += 1
          this.isParent[col][k]['currentPage'] += 1
        }
      }
      let data = {
        type: 'select-paging-sdki',
        page: page,
        search: this.isParent[col][k]['search']
      }

      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.$parent.mSDKI = res.data.data
      })
    },

    filterSDKI: _.debounce(function (e, k) {
      if (e) {
        this.diagnosa[k]['search'] = e
        Gen.apiRest('/do/' + this.$parent.modulePage, {
          data: {
            type: 'select-paging-sdki',
            search: e
          }
        }, 'POST').then(res => {
          this.$parent.mSDKI = res.data.data
          this.diagnosa[k]['currentPage'] = 1
          this.diagnosa[k]['totalRows'] = res.data.totalRows
        })
      }
    }, 10),

    changePageSLKI(page, k, k1, act, col, diagnosa) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this.isParent[col][k][diagnosa][k1]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this.isParent[col][k][diagnosa][k1]['totalRows'] / 10)) {
          page += 1
          this.isParent[col][k][diagnosa][k1]['currentPage'] += 1
        }
      }
      let data = {
        type: 'select-paging-slki',
        page: page,
        diagnosa_id: this.isParent[col][k]['arktsl_diagnosa_id'],
        search: this.isParent[col][k]['search']
      }

      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mSLKI = res.data.data
      })
    },

    filterSLKI: _.debounce(function (e, k, k1) {
      if (e) {
        this.diagnosa[k]['slki'][k1]['search'] = e
        Gen.apiRest('/do/' + this.$parent.modulePage, {
          data: {
            type: 'select-paging-slki',
            search: e,
            diagnosa_id: this.isParent['diagnosa'][k]['arktsl_diagnosa_id']
          }
        }, 'POST').then(res => {
          this.mSLKI = res.data.data
          this.diagnosa[k]['slki'][k1]['currentPage'] = 1
          this.diagnosa[k]['slki'][k1]['totalRows'] = res.data.totalRows
        })
      }
    }, 10),

    changePageSIKI(page, k, k2, act, col, diagnosa) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this.isParent[col][k][diagnosa][k2]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this.isParent[col][k][diagnosa][k2]['totalRows'] / 10)) {
          page += 1
          this.isParent[col][k][diagnosa][k2]['currentPage'] += 1
        }
      }
      let data = {
        type: 'select-paging-siki',
        page: page,
        diagnosa_id: this.isParent[col][k]['arktsi_diagnosa_id'],
        search: this.isParent[col][k]['search']
      }

      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mSIKI = res.data.data
      })
    },

    filterSIKI: _.debounce(function (e, k, k2) {
      if (e) {
        this.diagnosa[k]['siki'][k2]['search'] = e
        Gen.apiRest('/do/' + this.$parent.modulePage, {
          data: {
            type: 'select-paging-siki',
            search: e,
            diagnosa_id: this.isParent['diagnosa'][k]['arktsi_diagnosa_id']
          }
        }, 'POST').then(res => {
          this.mSIKI = res.data.data
          this.diagnosa[k]['siki'][k2]['currentPage'] = 1
          this.diagnosa[k]['siki'][k2]['totalRows'] = res.data.totalRows
        })
      }
    }, 10),

    selectPenyebab(e, k) {
      if (e) {
        let index = this.$parent.mSDKI.findIndex(x => x.value == e)
        if (index !== -1) {
          this.diagnosa[k]['arkts_config_sdki'] = this.$parent.mSDKI[index]
          this.diagnosa[k]['text'] = this.$parent.mSDKI[index]['text']

          for (let i = 0; i < (this.diagnosa[k]['slki'] || []).length; i++) {
            this.removeLuaran(this.diagnosa[k]['slki'][i].arktsl_id, k, i)

          }

          for (let i = 0; i < (this.diagnosa[k]['siki'] || []).length; i++) {
            this.removeIntervensi(this.diagnosa[k]['siki'][i].arktsi_id, k, i)
          }

        }
      }
    },

    addData(property) {
      if (!this.row[property]) {
        this.row[property] = [{}]
      } else {
        this.row[property].push({})
      }
    },
    removeData(property, keyData) {
      this.row[property].splice(keyData, 1)
    },
    checkCheckedValue(value, data) {
      return data.map(dt => dt.value).indexOf(value) >= 0
    },

    doSubmit() {
      this.$refs['VForm'].validate().then(success => {
        if (!success) return false

        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
            <span style="font-size: 16px">
              Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
            </span>              
          `,

          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            const payload = {
              ...this.row,
              is_draft: 'N',
              id_registrasi: this.$route.params.pageSlug
            }
            this.$swal.fire({
              title: 'Loading...',
              showConfirmButton: false,
              allowOutsideClick: false,
              didOpen: () => {
                this.$swal.showLoading()
              }
            })

            Gen.apiRest(
              "/do/" + this.$parent.modulePage, {
                data: {
                  ...payload,
                  type: this.row.id ? 'update' : 'add'
                }
              },
              "POST"
            ).then(resp => {
              this.$swal.close()
              this.$swal({
                title: resp.data.message,
                icon: 'success',
                confirmButtonText: 'Ok'
              }).then(result => {
                if (result.value) {
                  this.$router.back()
                }
              })
            }).catch(() => {
              this.$swal.close()

              return this.$swal({
                icon: 'error',
                text: 'Terjadi Kesalahan Yang Tidak Diketahui'
              })
            })
          }
        })
      })
    },
    updateRow(data) {
      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: {
            ...data,
            id_registrasi: this.$route.params.pageSlug,
            type: this.$route.params.pageSlug ? 'update' : 'add'
          }
        },
        "POST"
      )
    },
    autoSaveDiagnosa: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-diagnosa',
        data: data
      }

      Gen.apiRest(
        "/do/" + this.$parent.modulePage, {
          data: dataPost
        },
        "POST"
      )
    }, 1000),
  },
  watch: {
    diagnosa: {
      handler(v) {
        this.autoSaveDiagnosa(v)
      },
      deep: true
    }
  }
}
</script>
