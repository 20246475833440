<template>
<div class="content-wrapper overflow-visible">
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

  <div class="content">
    <template v-if="isListDetail">
      <div class="page-title pt-0 pb-3">
        <router-link :to="{ name: 'RanapPasienPerawatan' }" class="text-dark">
          <i class="icon-arrow-left52 mr-1"></i>
          <span class="font-weight-semibold">Kembali</span>
        </router-link>
      </div>

      <div class="row g-2">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="d-flex align-items-center">
                <h5 class="card-title font-weight-semibold mr-auto">Monitoring Kajian TBC</h5>
                <a href="javascript:;" v-if="moduleRole('add') && !has_draft_data" @click="$router.push({ ...$route, query: { viewType: 'FORM' } })" class="btn bg-blue-600 btn-labeled btn-labeled-left">
                  <b><i class="icon-plus2"></i></b>Buat Kajian TBC
                </a>
              </div>
            </div>

            <div class="card-body">
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Tanggal/Jam</th>
                    <th>Hasil Pemeriksaan</th>
                    <th>Perawat</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in tData.data" :key="`datas-${k}`">
                    <td>
                      <span class="font-weight-semibold" v-b-tooltip.hover title="Lihat Detail">{{ k+1 }}</span>
                    </td>
                    <td>
                      {{ (v.updated_date||v.created_date) | moment('LLL') }}

                      <p class="mb-0 mt-2" v-if="v.is_draft == 'Y'">
                        <b-badge variant="danger">Draft</b-badge>
                      </p>
                    </td>
                    <td>{{ "Thorax" }}</td>
                    <td>{{ v.nama_petugas }}</td>
                    <td>
                      <a href="javascript:;" v-if="v.is_draft == 'N'" class="btn btn-icon alpha-blue border-blue mr-2" v-b-tooltip.hover title="Lihat Kajian TBC" @click="openDetail(v.id)"><i class="icon-file-eye"></i></a>


                      <router-link :to="{ name: modulePage, params: { ...$route.params, kajianNo: v.id }, query: { viewType: 'FORM' } }" class="btn btn-icon alpha-warning border-warning" v-b-tooltip.hover title="Edit"><i class="icon-pencil4"></i></router-link>
                      <a href="javascript:;" v-if="v.is_draft == 'Y' && moduleRole('delete')" class="btn btn-icon alpha-warning border-warning ml-2" v-b-tooltip.hover title="Hapus Kajian TBC" @click="deleteData(v.id)">
                        <i class="icon-trash"></i>
                      </a>
                    </td>
                  </tr>

                  <tr v-if="tData.page.total_data < 1">
                    <td colspan="10">
                      <h5 class="mb-0 text-center">Belum ada data monitoring</h5>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-end" v-if="tData.page.total_data > tData.page.per_page">
              <b-pagination class="mb-0" v-model="pageNoTData" :per-page="tData.page.per_page" :total-rows="tData.page.total_data" />
            </div>
          </div>
        </div>

      </div>

      <b-modal id="detailKajian" size="xl" title="Detail Kajian TBC" hide-footer>
        <div class="row mt-2">
          <div class="col-md-12 mb-2">
            <div class="bg_card_blue p-3">
              <table class="table table-borderless table-sm text-uppercase">
                <tbody>
                  <tr>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>No. Reg</h4>
                        <p>{{ patientData.aranr_reg_code }}</p>
                      </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>No. Rekam Medis</h4>
                        <p>{{ patientData.ap_code }}</p>
                      </div>
                    </td>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{ patientData.ap_fullname }}</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="33%">
                      <div class="result_tab">
                        <h4>Tempat, Tanggal Lahir </h4>
                        <p>{{ patientData.ap_pob }}, {{ patientData.ap_dob | moment("DD MMM YYYY") }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Usia</h4>
                        <p>{{patientData.ap_usia_with_ket}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Jenis Kelamin</h4>
                        <p>{{ patientData.cg_label }}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Anamnesa</h6>
          </div>

          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Keluhan Utama</h4>
                            <p>{{ dataModal.keluhan_utama }}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Riwayat Penyakit Sekarang</h4>
                            <p>{{ dataModal.riwayat_penyakit_sekarang }}</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Riwayat Penyakit Dahulu</h4>
                            <p>{{ dataModal.riwayat_penyakit_dahulu }}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card mb-0">
                  <div class="card-header bg_head_panel">
                    <h6 class="card-title font-weight-semibold">Alergi</h6>
                  </div>
                  <div class="card-body p-3">
                    <div class="form-row">
                      <div class="col-md-12">
                        <div class="table-responsive" v-if="dataModal.has_alergi == 'Y'">

                          <table class="table table-bordered table-striped table-hover table-sm patient-table">
                            <thead>
                              <tr>
                                <th>Jenis</th>
                                <th>Informasi Alergi</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(v, k) in dataModal.alergi" :key="`alergi-${k}`">
                                <td class="text-capitalize">{{ v.jenis.toLowerCase() }}</td>
                                <td>{{ v.name }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <h5 class="text-center mb-0" v-else>Pasien tidak memiliki alergi</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
          </div>
          <div class="card-body p-3">
            <div class="table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Tekanan Darah</h4>
                        <p>{{ dataModal.sistolik }}/{{ dataModal.diastolik }} mmHG</p>
                      </div>
                    </td>
                    <td colspan="2">
                      <div class="result_tab">
                        <h4>Nadi</h4>
                        <p>{{ dataModal.nadi }}x/mnt - <span class="text-capitalize">{{ dataModal.nadi_type.toLowerCase() }}</span></p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Pernafasan</h4>
                        <p>{{ dataModal.pernafasan }}x/mnt</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>SPO2</h4>
                        <p>{{ dataModal.sp_o2 }}%</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Suhu</h4>
                        <p>{{ dataModal.suhu }}C</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Berat Badan</h4>
                        <p>{{ dataModal.berat_badan }}kg</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tinggi</h4>
                        <p>{{ dataModal.tinggi_badan }}Cm</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Lingkar Kepala</h4>
                        <p>{{ dataModal.lingkar_kepala }}cm</p>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div class="result_tab">
                        <h4>BMI</h4>
                        <p>{{ dataModal.bmi }}kg/m2</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Pengkajian Fisik</h6>
          </div>
          <div class="card-body p-3">
            <div>
              <table class="table table-bordered table-sm">
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Kondisi Umum</h4>
                      <p class="text-capitalize">{{ dataModal.kondisi_umum.toLowerCase() }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Glasgow Coma Scale (GCS)</h4>
                      <ul>
                        <li>E: tidak ada respon meskipun sudah dirangsang <span class="font-weight-semibold text-info-800">(1)</span></li>
                        <li>V: mengucapkan kata-kata yang tidak jelas <span class="font-weight-semibold text-info-800">(3)</span></li>
                        <li>M: menarik anggota gerak karena rangsangan nyeri <span class="font-weight-semibold text-info-800">(4)</span></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </table>
              <div class="wrap_line mt-3">
                <h3>Kepala</h3>
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Konjungtiva</h4>
                        <p>{{ dataModal.kep_konjungtiva }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Sianosis</h4>
                        <p>{{ dataModal.kep_sianosis }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Sklera</h4>
                        <p>{{ dataModal.kep_sklera }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Hidung</h4>
                        <p>{{ dataModal.kep_hidung }}</p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="wrap_line">
                <h3>Thorax / Dada</h3>
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Paru</h4>
                        <p>{{ dataModal.trx_respirasi }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Respirasi</h4>
                        <p>{{ dataModal.trx_respirasi }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Alat Bantu Nafas</h4>
                        <p>{{ dataModal.trx_alat_bantu_nafas }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Jantung</h4>
                        <p>{{ dataModal.trx_jantung }} <span v-if="dataModal.trx_jantung_note">- {{ dataModal.trx_jantung_note }}</span></p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="wrap_line">
                <h3>Abdomen</h3>
                <table class="table table-bordered table-sm">
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>Nyeri Tekan</h4>
                        <p>{{ dataModal.abd_nyeri == 'Y' ? 'Ya' : 'Tidak' }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Bising Usus</h4>
                        <p>{{ dataModal.abd_bising_usus == 'Y' ? 'Ya' : 'Tidak' }}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Kondisi Abdomen</h4>
                        <p>{{ dataModal.abd_kondisi }}</p>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <table class="table table-bordered table-sm">
                <tr>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Hasil Pemeriksaan TCM</h4>
                      <p>{{ dataModal.aka_hasil_tcm }}</p>
                    </div>
                  </td>
                  <td width="50%">
                    <div class="result_tab">
                      <h4>Hasil Pemeriksaan BTA</h4>
                      <p>{{ dataModal.aka_hasil_bta }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Hasil Rontgen Thorax</h4>
                      <p>{{ dataModal.aka_hasil_thorax }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Hasil Pemeriksaan PA / Mantoux Test</h4>
                      <p>{{ dataModal.aka_hasil_test }}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Edukasi </h6>
          </div>
          <div class="card-body p-3">
            <div>
              <div class="d-flex align-items-center mb-2" v-for="(v, k) in dataModal.edukasi" :key="`edukasi-${k}`">
                <i class="icon-checkmark-circle text-success"></i>
                <span class="ml-1 text-capitalize">{{ v.toLowerCase() }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-if="diagnosa.length > 0">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Asesmen Keperawatan</h6>
          </div>
          <div class="card-body p-3">
            <div class="card border shadow-0">
              <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Standar Diagnosis Keperawatan Indonesia (SDKI)
                </h5>
              </div>
              <div class="card-body">
                <div class="tab-content card-body p-0">
                  <b-tabs nav-class="nav-tabs-bottom" v-model="tabSDKI">
                    <b-tab :title="dtSdki.msd_code" v-for="(dtSdki, k) in diagnosa" :key="`dt-diagnosa-${k}`">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Diagnosa</h4>
                                      <p>{{ dtSdki.arkts_config_sdki.text }}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="form-row mt-2">
                        <div class="col-md-12">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Penyebab</h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-12">
                                  <div class="d-flex align-items-center  mb-2" v-for="(v, k) in dtSdki.arkts_config_sdki.msd_penyebab" :key="`penyebab-${k}`">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{ v.name }}</span>
                                  </div>

                                  <span v-if="!(dtSdki.arkts_config_sdki.msd_penyebab||[]).length">Tidak ada</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor
                              </h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-6">
                                  <h6 class="font-weight-semibold">Subjektif</h6>
                                  <div class="d-flex align-items-center  mb-2" v-for="(v, k) in dtSdki.arkts_config_sdki.msd_gejala_minor_subjektif" :key="`gejala_minmsd_gejala_minor_subjektif-${k}`">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{ v.name }}</span>
                                  </div>

                                  <span v-if="!(dtSdki.arkts_config_sdki.msd_gejala_minor_subjektif||[]).length">Tidak ada</span>
                                </div>
                                <div class="col-md-6">
                                  <h6 class="font-weight-semibold">Objektif</h6>
                                  <div class="d-flex align-items-center  mb-2" v-for="(v, k) in dtSdki.arkts_config_sdki.msd_gejala_minor_objektif" :key="`gejala_minor_subjemsd_gejala_minor_objektif-${k}`">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{ v.name }}</span>
                                  </div>

                                  <span v-if="!(dtSdki.arkts_config_sdki.msd_gejala_minor_objektif||[]).length">Tidak ada</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor
                              </h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-6">
                                  <h6 class="font-weight-semibold">Subjektif</h6>
                                  <div class="d-flex align-items-center  mb-2" v-for="(v, k) in dtSdki.arkts_config_sdki.msd_gejala_mayor_subjektif" :key="`gejala_mamsd_gejala_mayor_subjektif-${k}`">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{ v.name }}</span>
                                  </div>

                                  <span v-if="!(dtSdki.arkts_config_sdki.msd_gejala_mayor_subjektif||[]).length">Tidak ada</span>
                                </div>
                                <div class="col-md-6">
                                  <h6 class="font-weight-semibold">Objektif</h6>
                                  <div class="d-flex align-items-center  mb-2" v-for="(v, k) in dtSdki.arkts_config_sdki.msd_gejala_mayor_objektif" :key="`gejala_mayor_msd_gejala_mayor_objektif-${k}`">
                                    <i class="icon-checkmark-circle text-success"></i>
                                    <span class="ml-1">{{ v.name }}</span>
                                  </div>

                                  <span v-if="!(dtSdki.arkts_config_sdki.msd_gejala_mayor_objektif||[]).length">Tidak ada</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Standar Luaran Keperawatan
                                Indonesia
                                (SLKI)
                              </h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-12">
                                  <b-tabs nav-class="nav-tabs-bottom" v-model="tabSLKI">
                                    <b-tab :title="dtSlki.msl_code" v-for="(dtSlki, kSlki) in dtSdki.slki" :key="`slki-${kSlki}`">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div class="result_tab">
                                                      <h4>Luaran</h4>
                                                      <p>{{ dtSlki.text }}</p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <div class="col-md-12">
                                          <table class="table table-bordered table-striped table-hover table-sm patient-table">
                                            <thead>
                                              <tr>
                                                <th>Kriteria</th>
                                                <th>Skor</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr v-for="(v, k) in dtSlki.arktsl_kriteria_hasil" :key="`kh-${k}`">
                                                <td>
                                                  {{ v.name }}
                                                </td>
                                                <td>{{ v.hasil }}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </b-tab>
                                  </b-tabs>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Standar Intervensi Keperawatan
                                Indonesia
                                (SIKI)
                              </h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-12">
                                  <b-tabs nav-class="nav-tabs-bottom" v-model="tabSIKI">
                                    <b-tab :title="dtSiki.msi_code" v-for="(dtSiki, kSiki) in dtSdki.siki" :key="`siki-${kSiki}`">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div class="result_tab">
                                                      <h4>Intervensi</h4>
                                                      <p>{{ dtSiki.text }}</p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <div class="col-md-12">
                                          <table class="table table-bordered table-striped table-hover table-sm patient-table">
                                            <thead>
                                              <tr>
                                                <th>Tindakan</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <template v-if="dtSiki.arktksi_tindakan_observasi.length">
                                                <tr>
                                                  <th colspan="2" class="table-light">OBSERVASI</th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {{ dtSiki.arktksi_tindakan_observasi.map(v => v.name).join(", ") }}
                                                  </td>
                                                </tr>
                                              </template>
                                              <template v-if="dtSiki.arktksi_tindakan_edukasi.length">
                                                <tr>
                                                  <th colspan="2" class="table-light">EDUKASI</th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {{ dtSiki.arktksi_tindakan_edukasi.map(v => v.name).join(", ") }}
                                                  </td>
                                                </tr>
                                              </template>
                                              <template v-if="dtSiki.arktksi_tindakan_kolaborasi.length">
                                                <tr>
                                                  <th colspan="2" class="table-light">KOLABORASI</th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {{ dtSiki.arktksi_tindakan_kolaborasi.map(v => v.name).join(", ") }}
                                                  </td>
                                                </tr>
                                              </template>

                                              <template v-if="dtSiki.arktksi_tindakan_terapeutik.length">
                                                <tr>
                                                  <th colspan="2" class="table-light">TERAPEUTIK</th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {{ dtSiki.arktksi_tindakan_terapeutik.map(v => v.name).join(", ") }}
                                                  </td>
                                                </tr>
                                              </template>

                                              <template v-if="dtSiki.arktksi_tindakan_tambahan.length">
                                                <tr>
                                                  <th colspan="2" class="table-light">TAMBAHAN</th>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    {{ dtSiki.arktksi_tindakan_tambahan.map(v => v.name).join(", ") }}
                                                  </td>
                                                </tr>
                                              </template>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </b-tab>
                                  </b-tabs>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="card border shadow-0">
              <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Tindakan Keperawatan</h5>
              </div>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Diagnosa</th>
                      <th>Tindakan Keperawatan</th>
                      <th>PPA</th>
                      <th>Waktu</th>
                      <th>Tindakan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, k) in diagnosa[tabSDKI].arkts_tindakan_keperawatan" :key="`tk-${k}`">
                      <td rowspan="3">Nyeri Akut</td>
                      <td>{{ v.name }}</td>
                      <td>{{ v.ppa }}</td>
                      <td>
                        {{ v.dates | moment("DD/MM/YYYY") }} {{ v.time.HH }}:{{ v.time.mm }}
                      </td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <div class="result_tab">
                    <h4> Informasi Tambahan Tindakan</h4>
                    <p>
                      {{ diagnosa[tabSDKI].arkts_informasi_tindakan }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </template>
    <template v-else>
      <Form />
    </template>
  </div>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Form from './Form.vue'

export default {
  extends: GlobalVue,
  components: {
    Form,
    PatientInfo
  },
  data() {
    return {
      patientData: {},
      loading: {
        patientInfo: false,
        coreData: false
      },

      rowReg: {},
      isMonitoring: false,
      modalConfig: {
        title: '',
        size: 'sm'
      },
      isModal: '',
      rowEdit: {},
      openModal: false,
      dataKajian: [],

      mrEdukasi: [],
      mrKesadaran: [],
      mrJenisAlergi: [],
      mrGCS: {},
      mrKondisiUmum: [],

      mrValidation: {},

      tData: {
        data: [],
        page: {
          total_data: 0,
          per_page: 10,
          current_page: 1,
          is_loading: false
        }
      },
      has_draft_data: false,

      pageNoTData: 1,

      row: {},
      diagnosa: [],
      dataKajianPerawat: {},
      dataModal: {
        nadi_type: '',
        kondisi_umum: ''

      },
      mSDKI: [],

      isAddMonitoring: false,

      tabSDKI: 0,
      tabSLKI: 0,
      tabSIKI: 0,
    }
  },
  computed: {
    isListDetail() {
      return !this.$route.query.viewType
    },
    viewType() {
      return this.$route.query.viewType
    }
  },
  methods: {
    deleteData(id){
      this.$swal.fire({
        icon: 'warning',
        title: "Apakah Anda yakin ingin menghapus data ini?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
      }).then(btn => {
        if(btn.isConfirmed){
          this.$swal.fire({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: {
                type: "remove",
                id
              }
            },
          ).then(res => { 
            this.$swal.close()
            this.$swal({
              title: res.data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then(result => {
              if (result.value) {
                this.getList()
              }
            })
          }).catch(()=>{
            this.$swal.close()

            return this.$swal({
              icon: 'error',
              text: 'Terjadi Kesalahan Yang Tidak Diketahui'
            })
          })
        }
      })
    },
    back(){
      this.$router.push({name : 'RanapPasienPerawatan'}).catch({})
    },
    openDetail(id) {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-detail",
            id
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', res.data.data)
        this.$set(this, 'diagnosa', res.data.diagnosa)

        this.$bvModal.show("detailKajian")
      })
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getList() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-list",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })

        this.$set(this, 'loadingOverlay', false)
      })
    },

    getForm() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_registrasi: this.$route.params.pageSlug,
            id_kajian: this.$route.params.kajianNo
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    }
  },
  mounted() {
    this.getPatientInfo()
    if (this.viewType == "FORM") {
      this.getForm()
    } else {
      this.getList()
    }
  },
  watch: {
    $route() {
      this.getPatientInfo()
      if (this.viewType == "FORM") {
        this.getForm()
      } else {
        this.getList()
      }
    },
    tabSDKI(){
      this.$set(this, 'tabSLKI', 0)
      this.$set(this, 'tabSIKI', 0)
    },
    tabSLKI(){
      this.$set(this, 'tabSIKI', 0)
    },
  }
}
</script>
